import Vue from 'vue';
import VueRouter from 'vue-router';
// Guards
import { handleHelpWidgetClosing } from '@/help/router/guards';
// Module Routes
import { isDev } from '@/helpers';
import { offersRoutes } from '@/offers/router/routes';
import { playgroundRoutes } from '@/playground/router/routes';
import {
  handleLoggedOutUsers,
  handleNonPublicRoutes,
  handlePreviousTenantSpecificLoginRoute,
  handleUniversalLoginRedirection,
  handleV2RoutesInDevMode,
  loggedInActions,
  multiGuard,
} from '@/router/guards';
import { handleChunkLoadError, isChunkLoadError } from '@/router/handleChunkLoadError';
import { redirectToV2 } from '@/utils/redirect';
import { IS_PUBLIC, NO_AUTH_NEEDED } from '@/utils/symbols';

const PagesLogin = () => import('@pages/login.vue');

const routes = [
  {
    path: '/welcome-back',
    name: 'welcome-back',
    component: PagesLogin,
    meta: {
      [NO_AUTH_NEEDED]: true,
    },
    beforeEnter(to, from, next) {
      if (!isDev) {
        redirectToV2(to);
      } else {
        next();
      }
    },
  },
  ...offersRoutes,
  ...playgroundRoutes,
  {
    // Wild-card routes should be last:
    // https://router.vuejs.org/guide/essentials/dynamic-matching.html#catch-all-404-not-found-route
    // delegate to v2
    path: '*',
    name: 'not-found',
    meta: {
      [NO_AUTH_NEEDED]: true,
      [IS_PUBLIC]: true,
    },
    beforeEnter(to) {
      redirectToV2(to);
    },
  },
];

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  routes,
});

router.beforeEach(
  multiGuard(
    handleV2RoutesInDevMode,
    handleUniversalLoginRedirection,
    handleNonPublicRoutes,
    handlePreviousTenantSpecificLoginRoute,
    handleHelpWidgetClosing,
    handleLoggedOutUsers,
    loggedInActions
  )
);

/* Handle ChunkLoadError */
handleChunkLoadError(router);

/* Mute NavigationDuplicated and ChunkLoad errors and only these */
const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err) || isChunkLoadError(err)) {
      return err;
    }

    return Promise.reject(err);
  });
};

export { router };
