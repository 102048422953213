import { OFFER_STATUS } from '@/enums';
import store from '@/store';
import { redirectToV2 } from '@/utils/redirect';
import { ACTIVITY_ADD_COMMENTS } from '@/utils/symbols';

const OrganismsOffer = () => import('@/offers/components/dashboard/offer.vue');
const PagesAnalytics = () => import('@/offers/pages/analytics.vue');
const PagesDashboard = () => import('@/offers/pages/dashboard.vue');
const PagesExtensionCandidateCreate = () => import('@/offers/pages/extension-candidate-create.vue');
const PagesOfferForm = () => import('@/offers/pages/offer-form.vue');

// TODO: Settings Revamp Cleanup - delete these settings pages
// const CompTableSettings = () => import('@/offers/pages/comp-table-settings');
// const PagesApprovalChains = () => import('@/offers/pages/approval-chains');
// const PagesDocusign = () => import('@/offers/pages/docusign');
// const PagesOfferTemplates = () => import('@/offers/pages/offer-templates');

// Register layouts as global components here
// Vue.component('LayoutsDefault', LayoutsDefault);

const beforeEnterCallback = (to, from, next) => {
  if (store.state.auth.profile.app_access.nav.offers) {
    return next();
  } else {
    next({ name: 'home' });
  }
};

const beforeEnterCallbackOfferAnalytics = (to, from, next) => {
  if (store.state.auth.profile.app_access.nav.offer_analytics) {
    return next();
  } else {
    next({ name: 'home' });
  }
};

export const offersRoutes = [
  {
    path: '/analytics',
    name: 'analytics',
    component: PagesAnalytics,
    beforeEnter: beforeEnterCallbackOfferAnalytics,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: PagesDashboard,
    beforeEnter: (to, from, next) => {
      if (to.hash) {
        store.dispatch('offers/setOffer', {});

        let selectedOfferStatus = OFFER_STATUS.DRAFT;

        switch (to.hash.replace('#', '').toLowerCase()) {
          case 'drafts':
            selectedOfferStatus = OFFER_STATUS.DRAFT;
            break;
          case 'pending':
            selectedOfferStatus = OFFER_STATUS.PENDING;
            break;
          case 'approved':
            selectedOfferStatus = OFFER_STATUS.APPROVED;
            break;
        }
        store.commit('offerFilter/setSelectedOfferStatus', selectedOfferStatus, { root: true });
      }

      beforeEnterCallback(to, from, next);
    },
  },
  {
    path: '/dashboard/offer/:id',
    component: PagesDashboard,
    children: [{ path: '', name: 'offer', component: OrganismsOffer }],
    beforeEnter: beforeEnterCallback,
  },
  {
    path: '/create-candidate',
    name: 'extension-create-candidate',
    component: PagesExtensionCandidateCreate,
    beforeEnter: beforeEnterCallback,
  },
  {
    path: '/offers/new',
    name: 'offer-form',
    component: PagesOfferForm,
    beforeEnter: beforeEnterCallback,
  },
  {
    path: '/offer/:id/edit',
    name: 'edit-offer',
    component: PagesOfferForm,
    meta: {
      [ACTIVITY_ADD_COMMENTS]: false,
    },
    beforeEnter: beforeEnterCallback,
  },
  {
    path: '/candidate/:id',
    name: 'ats-candidate',
    component: PagesOfferForm,
    meta: {
      [ACTIVITY_ADD_COMMENTS]: false,
    },
    beforeEnter: beforeEnterCallback,
  },
  {
    path: '/candidate/:id/applications/:application_id',
    name: 'ats-candidate-application',
    component: PagesOfferForm,
    meta: {
      [ACTIVITY_ADD_COMMENTS]: false,
    },
    beforeEnter: (to, from, next) => {
      if (store.getters['companies/isUsingOffersV1Modern']) {
        return redirectToV2(to);
      }

      beforeEnterCallback(to, from, next);
    },
  },
];
