import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import { api } from '@/axios/instance/api';
import { isFeatureTest } from '@/helpers';
import { apiFetchAllRegions } from '@/offers/api/dashboard';
import { apiFetchFxRates } from '@/offers/api/offers-common';

const protocol = window.location.protocol + '//';
const split = window.location.host.split(':');

const port = isFeatureTest ? window.localStorage.getItem('TestServerPort') : '8000';
const newHost = split.length > 1 ? split[0] + `:${port}` : split[0];
const urlBase = protocol + newHost;

const state = {
  companies: [],
  companyRanges: [],
  companyDepartments: [],
  allCompanyDepartments: [],
  countries: [],
  allCountries: [],
  geos: [],
  allGeos: [],
  regions: [],
  allRegions: [],
  levels: [],
  allLevels: [],
  jobs: [],
  allJobs: [],
  ghJobs: [],
  idps: [],
  hasAnalytics: true,
  tableRows: [],
  fxRates: null,
};

const getters = {
  getField,
  companies: (state) => state.companies,
  freePage: (state) => Boolean(state.companies?.[0]?.free_page),
  hasSalaryBoard: (state) => Boolean(state.companies?.[0]?.has_salary_board),
  showRangesHourlyRates: (state) => Boolean(state.companies?.[0]?.show_ranges_hourly_rates),
  usingCrypto: (state) => Boolean(state.companies?.[0]?.using_crypto),
  tenant: (state) => state.companies[0].name,
  getCompanyRanges: (state) => state.companyRanges,
  getCompanyDepartments: (state) => state.companyDepartments,
  getAllCompanyDepartments: (state) => state.allCompanyDepartments,
  getCountries: (state) => state.countries,
  getAllCountries: (state) => state.allCountries,
  getGeos: (state) => state.geos,
  getAllGeos: (state) => state.allGeos,
  getRegions: (state) => state.regions,
  getAllRegions: (state) => state.allRegions,
  getLevels: (state) => state.levels,
  getAllLevels: (state) => state.allLevels,
  getJobs: (state) => state.jobs,
  getAllJobs: (state) => state.allJobs,
  getGhJobs: (state) => state.ghJobs,
  getIDPs: (state) => state.idps,
  companyName: (state) => state.companies?.[0]?.name,
  companyUploadedLogoImage: (state) => state.companies?.[0]?.logo_image,
  companyLogoUrl: (state) => {
    return state.companies?.[0]?.logo_image || state.companies?.[0]?.logo_url;
  },
  isUsingGreenhouse: (state) => state.companies.length > 0 && state.companies[0].using_greenhouse,
  isUsingMerge: (state) => state.companies.length > 0 && state.companies[0].using_merge,
  isUsingSSO: (state) => state.companies.length > 0 && state.companies[0].using_sso,
  isUsingMFA: (state) => state.companies.length > 0 && state.companies[0].using_mfa,
  isBonusPercentageFormat(state) {
    const targetIncentivePayTypes = { currency: 0, percentage: 1 };
    return state.companies?.[0]?.target_incentive_pay_type === targetIncentivePayTypes.percentage;
  },
  isFreeTier: (state) => state.companies.length > 0 && state.companies[0].is_free_tier,
  isUsingOffersV1: (state) => state.companies.length > 0 && state.companies[0].using_offers_v1,
  isUsingOffersV1Modern: (state) => {
    return (
      state.companies.length > 0 && state.companies[0]?.using_offers_v1 && state.companies[0]?.using_offers_v1_modern
    );
  },
  hasAnalytics: (state) => state.companies.length > 0 && state.companies[0].has_analytics,
  getTableRows: (state) => state.tableRows,
  isUsingVisualOffers: (state) => state.companies.length > 0 && state.companies[0].using_visual_offers,
  usingEquityV2: (state) => state.companies.length > 0 && state.companies[0].using_equity_v2,
  getFxRates: (state) => state.fxRates,
};

const mutations = {
  updateField,
  setCompanies(state, companies) {
    state.companies = companies;
  },
  setCompanyRanges(state, ranges) {
    state.companyRanges = ranges;
  },
  setCompanyDepartments(state, departments) {
    state.companyDepartments = departments;
  },
  setAllCompanyDepartments(state, departments) {
    // TODO: Fix this the next time the file is edited.

    state.allCompanyDepartments = departments;
  },
  setCountries(state, countries) {
    state.countries = countries;
  },
  setAllCountries(state, allCountries) {
    state.allCountries = allCountries;
  },
  setGeos(state, geos) {
    state.geos = geos;
  },
  setAllGeos(state, allGeos) {
    state.allGeos = allGeos;
  },
  setRegions(state, regions) {
    state.regions = regions;
  },
  setAllRegions(state, allRegions) {
    state.allRegions = allRegions;
  },
  setLevels(state, levels) {
    state.levels = levels;
  },
  setAllLevels(state, allLevels) {
    state.allLevels = allLevels;
  },
  setJobs(state, jobs) {
    state.jobs = jobs;
  },
  setAllJobs(state, allJobs) {
    state.allJobs = allJobs;
  },
  setGhJobs(state, ghJobs) {
    state.ghJobs = ghJobs;
  },
  setIDPs(state, idps) {
    state.idps = idps;
  },
  setTableRows(state, rows) {
    state.tableRows = rows.sort((a, b) => a.row_order - b.row_order);
  },
  setFxRates(state, fxRates) {
    state.fxRates = fxRates;
  },
};

const actions = {
  async fetchCompanies(context) {
    const { data } = await axios.get(urlBase + '/api/companies');
    return await context.commit('setCompanies', data);
  },
  async fetchCompanyRanges(context) {
    const { data: responseData, error } = await api.get('/company-ranges');
    if (!error) {
      context.commit('setCompanyRanges', responseData);
    }
  },
  async fetchCompanyDepartments(context) {
    const { data: responseData, error } = await api.get('/company-departments');
    if (!error) {
      const visibleDepartments = responseData.filter((department) => department.visible);
      context.commit('setCompanyDepartments', visibleDepartments);
    }
  },
  async fetchAllCompanyDepartments(context) {
    const { data: responseData, error } = await api.get('/company-departments');
    if (!error) {
      context.commit('setAllCompanyDepartments', responseData);
    }
  },
  async fetchCountries(context) {
    return context.dispatch('fetchAllCountries');
  },
  async fetchAllCountries(context) {
    const { data: responseData, error } = await api.get('/countries');
    if (!error) {
      context.commit('setAllCountries', responseData);
      const visibleCountries = responseData.filter((country) => country.visible);
      context.commit('setCountries', visibleCountries);
    }
  },
  async fetchGeos(context) {
    return context.dispatch('fetchAllGeos');
  },
  async fetchAllGeos(context) {
    const { data: responseData, error } = await api.get('/geos');
    if (!error) {
      context.commit('setAllGeos', responseData);
      const visibleGeos = responseData.filter((geo) => geo.visible);
      context.commit('setGeos', visibleGeos);
    }
  },
  async fetchAllRegions(context) {
    const { data: responseData, error } = await apiFetchAllRegions();
    if (!error) {
      context.commit('setAllRegions', responseData);
      const visibleRegions = responseData.filter((region) => region.visible);
      context.commit('setRegions', visibleRegions);
    }
  },
  async fetchLevels(context) {
    const { data: responseData, error } = await api.get('/levels');
    if (!error) {
      const visibleLevels = responseData.filter((level) => level.visible);
      context.commit('setLevels', visibleLevels);
    }
  },
  async fetchAllLevels(context) {
    const { data: responseData, error } = await api.get('/levels');
    if (!error) {
      context.commit('setAllLevels', responseData);
    }
  },
  async fetchJobs(context) {
    const { data: responseData, error } = await api.get('/jobs');
    if (!error) {
      const visibleJobs = responseData.filter((job) => job.visible);
      context.commit('setJobs', visibleJobs);
    }
  },
  async fetchAllJobs(context) {
    const { data: responseData, error } = await api.get('/jobs');
    if (!error) {
      context.commit('setAllJobs', responseData);
    }
  },
  async fetchFxRates({ commit }) {
    const { data: responseData, error } = await apiFetchFxRates();
    if (!error) {
      commit('setFxRates', responseData);
    }
  },
  fetchGhJobs(context) {
    axios.get(urlBase + '/api/real-gh-jobs').then((response) => {
      context.commit('setGhJobs', response.data);
    });
  },
  fetchIDPs(context) {
    axios.get(urlBase + '/api/idps').then((response) => {
      context.commit('setIDPs', response.data);
    });
  },
  async createCompanyDepartments({ dispatch }, data) {
    await axios.post(urlBase + '/api/company-departments', data);
    dispatch('fetchCompanyDepartments');
  },
  async createCountries({ dispatch }, data) {
    await axios.post(urlBase + '/api/countries', data);
    dispatch('fetchCountries');
  },
  async createGeos({ dispatch }, data) {
    await axios.post(urlBase + '/api/geos', data);
    dispatch('fetchGeos');
  },
  async createRegions({ dispatch }, data) {
    await axios.post(urlBase + '/api/regions', data);
    dispatch('fetchAllRegions');
  },
  async createLevels({ dispatch }, data) {
    await axios.post(urlBase + '/api/levels', data);
    dispatch('fetchLevels');
  },
  async createJobs({ dispatch }, data) {
    await axios.post(urlBase + '/api/jobs', data);
    await dispatch('fetchJobs');
  },
  async createGhJobs({ dispatch }, data) {
    await axios.post(urlBase + '/api/gh-jobs', data);
    dispatch('fetchGhJobs');
  },
  async updateCompany({ dispatch }, payload) {
    await axios.patch(urlBase + '/api/companies/' + `${payload.id}`, payload);
    await dispatch('fetchCompanies');
  },
  async updateCompanyImage({ dispatch }, payload) {
    const { id, image } = payload;
    const formData = new FormData();
    formData.append('logo_image', image);

    try {
      await axios.patch(`${urlBase}/api/companies/${id}/upload-logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await dispatch('fetchCompanies');
    } catch (error) {
      console.error(error);
    }
  },
  async deleteCompanyImage({ dispatch }, payload) {
    const { id } = payload;
    try {
      await axios.delete(`${urlBase}/api/companies/${id}/delete-logo`);
      await dispatch('fetchCompanies');
    } catch (error) {
      console.error(error);
    }
  },
  async updateCompanyDepartment({ dispatch }, payload) {
    await axios.patch(urlBase + '/api/company-departments/' + `${payload.id}`, payload);
    dispatch('fetchCompanyDepartments');
  },
  async updateJob({ dispatch }, payload) {
    await axios.patch(urlBase + '/api/jobs/' + `${payload.id}`, payload);
    dispatch('fetchJobs');
  },
  async updateCountry({ dispatch }, payload) {
    await axios.patch(urlBase + '/api/countries/' + `${payload.id}`, payload);
    dispatch('fetchCountries');
  },
  async updateGeo({ dispatch }, payload) {
    await axios.patch(urlBase + '/api/geos/' + `${payload.id}`, payload);
    dispatch('fetchGeos');
  },
  async updateRegion({ dispatch }, payload) {
    await axios.patch(urlBase + '/api/regions/' + `${payload.id}`, payload);
    dispatch('fetchAllRegions');
  },
  async updateLevel({ dispatch }, payload) {
    await axios.patch(urlBase + '/api/levels/' + `${payload.id}`, payload);
    dispatch('fetchLevels');
  },
  async bulkArchiveCompanyDepartments({ dispatch }, data) {
    await axios.post(urlBase + '/api/company-departments/bulk_archive', data);
    dispatch('fetchCompanyDepartments');
  },
  async bulkArchiveJobs({ dispatch }, data) {
    await axios.post(urlBase + '/api/jobs/bulk_archive', data);
    dispatch('fetchJobs');
  },
  async bulkArchiveCountries({ dispatch }, data) {
    await axios.post(urlBase + '/api/countries/bulk_archive', data);
    dispatch('fetchCountries');
  },
  async bulkArchiveGeos({ dispatch }, data) {
    await axios.post(urlBase + '/api/geos/bulk_archive', data);
    dispatch('fetchGeos');
  },
  async bulkArchiveRegions({ dispatch }, data) {
    await axios.post(urlBase + '/api/regions/bulk_archive', data);
    dispatch('fetchAllRegions');
  },
  async bulkArchiveLevels({ dispatch }, data) {
    await axios.post(urlBase + '/api/levels/bulk_archive', data);
    dispatch('fetchLevels');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
