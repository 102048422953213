import { PEQUITY_API_URL, PEQUITY_BASE_URL, PEQUITY_SOCKET_PROTOCOL, PEQUITY_SOCKETS_BASE_URL } from '@/environment';
import { ACCESS_COOKIE_NAME, ACCESS_TOKEN, REFRESH_TOKEN } from '@/utils/constants';

export const DEBUG = process.env.NODE_ENV === 'development';

// XXX This should be in the .env file.
export function getUrlBase() {
  const protocol = window.location.protocol + '//';
  const split = window.location.host.split(':');
  const newHost = split.length > 1 ? split[0] + ':8000' : split[0];
  return protocol + newHost;
}

export const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

export const isFeatureTest = !!window.localStorage.getItem('TestServerSubdomain');

export const URL_BASE = getUrlBase();

export const TENANT =
  window.location.hostname === PEQUITY_BASE_URL.split(':')[0] ? null : window.location.hostname.split('.')[0];

const getApiUrl = () => {
  const url = new URL(PEQUITY_API_URL);

  url.host = TENANT
    ? `${isFeatureTest ? window.localStorage.getItem('TestServerSubdomain') : TENANT}.${url.host}`
    : url.host;

  if (isFeatureTest) {
    url.port = `${window.localStorage.getItem('TestServerPort')}`;
  }

  return url.href;
};
export const API_URL = getApiUrl();

const wsSubdomain = TENANT ? `${TENANT}.` : '';
export const WEBSOCKETS_URL = isFeatureTest
  ? `${PEQUITY_SOCKET_PROTOCOL}://${wsSubdomain}${PEQUITY_SOCKETS_BASE_URL.split(':')[0]}:${window.localStorage.getItem('TestServerDaphnePort')}/ws`
  : `${PEQUITY_SOCKET_PROTOCOL}://${wsSubdomain}${PEQUITY_SOCKETS_BASE_URL}/ws`;

/**
 * WARNING
 * This function does not do what the name says. It does not convert to int. It converts to int or float.
 * So, if that's what you need, make sure to convert it's result to an actual integer.
 * I'd rename it but it's used 304 times at the moment of me writing this comment, and ain't nobody got time for that.
 */
export function convertStringToInt(input) {
  return typeof input === 'string' ? Number(input.replace(/[^0-9.]+/g, '')) : input;
}

export function isDev() {
  return import.meta.env.DEV;
}

export function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const tokenExpired = (token) => {
  if (!token) {
    return true;
  }
  const msExpiration = JSON.parse(atob(token.split('.')[1])).exp;
  const adjustedExpiration = msExpiration * 1000 - 15000;
  return adjustedExpiration < Date.now();
};

const setAccessCookie = (cookie) => {
  document.cookie = `${ACCESS_COOKIE_NAME}=${cookie}; path=/;`;
};

export const clearTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  setAccessCookie('');
  localStorage.removeItem(REFRESH_TOKEN);
};

export const createTenantObject = (obj) =>
  Object.entries(obj).reduce((acc, [key, values]) => {
    acc[key] = values[TENANT] || values.DEFAULT;
    return acc;
  }, {});

export const createUuid = () => window.crypto.randomUUID();

export const makeIterableWithUuid = (arr) => {
  return arr.map((elem) => {
    return [elem, createUuid()];
  });
};

const plural = (count, one, multiple) => (Math.abs(count) === 1 ? one : multiple);

export const displayCount = (count, one, multiple) => `${count || 0} ${plural(count, one, multiple)}`;

export const setAccessToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN, token);
  setAccessCookie(`Bearer ${token}`);
};

export const setRefreshToken = (token) => {
  localStorage.setItem(REFRESH_TOKEN, token);
};
